import { Link, useParams } from "react-router-dom";
import translate, { AvailableLanguages, AvailableLanguagesType } from "./i18n";
import LanguageContext, { defaultLanguage } from "./store/LanguageContext";
import WithAnimation from "./components/hoc/WithAnimation";
import Header from "./components/Layout/Header/Header";
import Content from "./components/Layout/Content/Content";
import Footer from "./components/Layout/Footer/Footer";

export const Main = () => {
    
    const routeProps = useParams();
    let language: AvailableLanguagesType = defaultLanguage;
    if (routeProps && routeProps.language) {
        language = routeProps.language as AvailableLanguagesType;
    }

    return (
        <LanguageContext.Provider value={language}>
            <ul className="language-picker">
                {AvailableLanguages.map((lngName, lngKey) => {
                    return (
                        <li key={lngKey}>
                            <Link to={`/${lngName}`} className={[lngName, (language === lngName || (!language && lngName === defaultLanguage) ? 'active' : '')].join(' ')}>
                                {translate(lngName, 'globals')}
                            </Link>
                        </li>
                    )
                })}
            </ul>
            <WithAnimation>
                <div id="Container">
                    <div id={!language ? defaultLanguage : language}>
                        <Header />
                        <section className="MainContent">
                            <Content />
                        </section>
                        <Footer />
                    </div>
                </div>
            </WithAnimation>
        </LanguageContext.Provider>
    )
}