import hu from './data/hu';
import en from './data/en';

const languageVars = {
    'globals': {
        'hu': 'Magyar',
        'en': 'English',
    },
    'hu': hu,
    'en': en
};

export default languageVars;