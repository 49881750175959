import * as React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { Main } from './Main';
import { Error404 } from './components/Layout/Error/Error404';

const App: React.FunctionComponent<{}> = () => {

	return (
		<BrowserRouter>
			<div id="Wrapper">
				<AnimatePresence initial={false}>
					<Routes>
						<Route index element={<Main />} />
						<Route path={"/:language"} element={<Main />} />
						<Route element={<Error404 />} />
					</Routes>
				</AnimatePresence>
			</div>
		</BrowserRouter>
	);
}

export default App;
